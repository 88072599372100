<template>
  <v-sheet>
    <Dialog :dialog="recordPaymentDialog" card-text-class="px-4 py-0" :dialogWidth="1000">
      <template v-slot:title>
        <span>Reservation {{ customer_email }}</span> <v-spacer></v-spacer
      ></template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="customerForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="saveRecord"
          >
            <template>
              <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
                <v-row>
                  <v-col md="6">
                    <v-row>
                      <v-col md="12">
                        <label class="field-label required"> Date </label>
                        <BookingDate
                          hide-details
                          :disabled="pageLoading"
                          :allowed-dates="filterTuesdays"
                          :minDate="dateFifteenDaysAgo"
                          :loading="pageLoading"
                          :rules="[vrules.required(reservation.booking_date, 'Supplier')]"
                          :customClass="{ required: !reservation.booking_date }"
                          @change="generateTimeSlots($event)"
                          id="payment-date"
                          v-model="reservation.booking_date"
                        ></BookingDate>
                      </v-col>

                      <v-col md="12" class="py-1">
                        <label class="field-label required mb-1">No of Guests</label>
                        <ReservationPerson
                          hide-details
                          :disabled="pageLoading"
                          :rules="[vrules.required(reservation.person, 'Supplier')]"
                          :class="{ required: !reservation.person }"
                          placeholder="No of Guests"
                          :loading="pageLoading"
                          id="payment-date"
                          v-model="reservation.person"
                        ></ReservationPerson>
                      </v-col>
                      <v-col md="12" class="py-1">
                        <label class="field-label required">Sources</label>
                        <AutoCompleteInput
                          hide-details
                          class="mt-1"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :rules="[vrules.required(reservation.source, 'Supplier')]"
                          :class="{ required: !reservation.source }"
                          :items="sources"
                          id="segment"
                          v-model="reservation.source"
                          placeholder="Select Event"
                        ></AutoCompleteInput>
                      </v-col>
                      <v-col md="12" class="py-1">
                        <label class="field-label required">Event</label>
                        <AutoCompleteInput
                          hide-details
                          class="mt-1"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :rules="[vrules.required(reservation.type, 'Supplier')]"
                          :class="{ required: !reservation.type }"
                          :items="events"
                          id="segment"
                          v-model="reservation.type"
                          placeholder="Select Event"
                        ></AutoCompleteInput>
                      </v-col>

                      <v-col md="12" class="py-1">
                        <label for="reference" class="field-label">Customers</label>

                        <v-combobox
                          hide-details
                          outlined
                          class="mt-1"
                          flat
                          :rules="[vrules.required(reservation.first_name, 'Supplier')]"
                          :class="{ required: !reservation.first_name }"
                          hideTopMargin
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :items="customerss"
                          :search-input.sync="search"
                          @blur="searchingIteams($event)"
                          @change="searchingIteam($event)"
                          @keyup="searchingIteam($event)"
                          item-value="first_name"
                          item-text="text"
                          id="segment"
                          v-model="reservation.first_name"
                          placeholder="Select Customer"
                        ></v-combobox>
                      </v-col>
                      <!-- <v-col md="12" class="py-1">
                  <label for="payment-date" class="field-label required mb-1">Customer Name</label>
                  <TextInput
                  hideTopMargin
                    hide-details
                    :disabled="pageLoading"

                    :loading="pageLoading"
                    id="payment-date"
                    v-model="reservation.customer_name"
                  ></TextInput>
                </v-col> -->
                      <v-col md="12" class="py-1">
                        <label for="payment-date" class="field-label required mb-1">Email</label>
                        <EmailInput
                          :rules="[vrules.required(reservation.email, 'Supplier')]"
                          :class="{ required: !reservation.email || customer_email }"
                          hideTopMargin
                          hide-details
                          :disabled="pageLoading"
                          @keyup="checkEmail(reservation.email)"
                          :loading="pageLoading"
                          id="company-email-address"
                          v-model="reservation.email"
                          placeholder=" Email Address"
                        ></EmailInput>
                      </v-col>

                      <span style="color: red" v-if="customer_email"> Email Already Exists</span>
                      <span style="color: red" v-else> </span>

                      <v-col md="12" class="py-1">
                        <label for="payment-date" class="field-label required mb-1"
                          >Phone Number</label
                        >
                        <PhoneTextField
                          hide-details
                          :disabled="pageLoading"
                          :rules="[vrules.required(reservation.mobile, 'Supplier')]"
                          :class="{ required: !reservation.mobile }"
                          @keydown="checkPhone(reservation.mobile)"
                          :loading="pageLoading"
                          v-model="reservation.mobile"
                          id="phone-number"
                          placeholder="Phone Number"
                        ></PhoneTextField>
                      </v-col>
                      <v-col md="12" class="py-1">
                        <label for="notes" class="field-label">Notes</label>
                        <TextAreaInput
                          v-model="reservation.notes"
                          auto-grow
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="notes"
                          placeholder=""
                        ></TextAreaInput>
                      </v-col>

                      <!-- <v-col md="12" class="py-1">
                  <label for="attachments" class="field-label">Attachments</label>

                  <PurchaseDocument
                    id="file"
                    :directionColumn="false"
                    v-on:uploadData="test($event)"
                    allowAddMore
                    v-model="paymentRecordData.attachments"
                  ></PurchaseDocument>
                </v-col> -->
                    </v-row>
                  </v-col>
                  <v-col md="6" mb="0">
                    <h3 style="font-weight: bold; color: #163566">Lunch</h3>

                    <v-btn-toggle
                      v-model="selectSlot"
                      tile
                      color="#6775e0"
                      group
                      class="d-flex flex-wrap"
                    >
                      <v-btn
                        width="100"
                        height="45"
                        class="onhover"
                        style="border: 1px solid #9e9e9e"
                        v-for="(row, index) in timeSlots"
                        :key="index"
                        :value="row.time"
                      >
                        {{ row.time }}
                      </v-btn>
                    </v-btn-toggle>
                    <br />
                    <h3 style="font-weight: bold; color: #163566">Coffee / Tea / Snacks</h3>

                    <v-btn-toggle
                      v-model="selectSlot"
                      tile
                      color="#6775e0"
                      group
                      class="d-flex flex-wrap"
                    >
                      <v-btn
                        width="100"
                        height="45"
                        class="onhover"
                        style="border: 1px solid #9e9e9e"
                        v-for="(row, index) in timeLunchSlots"
                        :key="index"
                        :value="row.time"
                      >
                        {{ row.time }}
                      </v-btn>
                    </v-btn-toggle>
                    <br />
                    <h3 style="font-weight: bold; color: #163566">Dinner</h3>

                    <v-btn-toggle
                      v-model="selectSlot"
                      tile
                      color="#6775e0"
                      group
                      class="d-flex flex-wrap"
                    >
                      <v-btn
                        width="100"
                        height="45"
                        class="onhover"
                        style="border: 1px solid #9e9e9e"
                        v-for="(row, index) in timedinnerSlots"
                        :key="index"
                        :value="row.time"
                      >
                        {{ row.time }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-layout>
            </template>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="saveRecord"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import PosMixin from "@/core/mixins/pos.mixin";
// import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import Combobox from "@/view/components/Combobox";
import moment from "moment";

import { isEmpty } from "lodash";

//import FileUpload from "@/view/components/FileUpload";
import BookingDate from "@/view/components/BookingDate";
import TextAreaInput from "@/view/components/TextAreaInput";
//import SelectInput from "@/view/components/SelectInput";
// import PurchaseDocument from "@/view/components/PurchaseDocument";
import { CREATE_RESERVATION, UPDATE_RESERVATION } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import MomentJS from "moment-timezone";
import PhoneTextField from "@/view/components/PhoneTextField";
import { QUERY } from "@/core/services/store/request.module";
import EmailInput from "@/view/components/EmailInput";
import ReservationPerson from "@/view/components/ReservationPerson";

export default {
  mixins: [PosMixin],

  name: "item-dialog",
  title: "Dialog Item",
  data() {
    return {
      selectSlot: "",
      formValid: true,
      selectSlots: "",
      product: [],
      dateFifteenDaysAgo: null,
      customer_email: null,
      timedinnerSlots: [],
      search: null,
      timeLunchSlotsL: [],
      search_year: null,
      timeSlots: [],
      customerss: [],
      selectedTime: null,
      date_bill: MomentJS().format("YYYY-MM-DD"),
      amount: 0,
      excelLoading: null,
      recordPaymentDialog: false,
      paymentThroughList: [
        { text: "Petty Cash", value: 1 },
        { text: "Undeposited Founds", value: 2 },
      ],
      paymentModeList: [
        { text: "Cash", value: 1 },
        { text: "Cheque", value: 2 },
      ],

      customers: [{ text: "John Doe", value: "john doe" }],
      events: [
        { text: "Birthday", value: "Birthday" },
        { text: "Mothers Day", value: "Mothers Day" },

        { text: "Anniversary", value: "Anniversary" },
        { text: "Promotion", value: "Promotion" },
        { text: "Event", value: "Event" },
        { text: "Family & Friends Gathering", value: "Family & Friends Gathering" },
      ],
      paymentRecord: [
        {
          po_number: "PO0001",
          venderName: "business thrust techsoft pvt ltd",
          primaryPayment: "",
          amount: 309.88,
        },
      ],
      paymentRecordData: {
        payment_made: 309.88,
        payment_number: "1",
        payment_date: "",
        payment_mode: "Bank Transfer",
        reference: "",
        payment_notes: "",
        attachments: [],
        barcode: null,
        notification_checkbox: false,
        sendemail1: true,
        sendemail2: false,
      },
      reservation: {
        booking_date: null,
        booking_time: "1",
        restaurant_id: "1",
        notes: null,
        person: "",
        id: null,
        type: null,
        first_name: null,
        customer_name: null,
        email: "",
        mobile: "",
        source: "",
      },
    };
  },
  watch: {
    dialog(params) {
      this.recordPaymentDialog = params;
      if (!isEmpty(this.data)) {
        this.reservation = this.data;
        this.reservation.person = this.data.no_of_guest;
        this.selectSlot = this.data.booking_time;
        this.reservation.first_name = this.data.customer_name;
        this.reservation.email = this.data.customer_email;
        this.reservation.mobile = this.data.customer_number;
        this.reservation.notes = this.data.message;
        this.id = this.data.id;
      } else {
        this.reservation = {};
      }
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    total: {
      type: Array,
      default: () => {
        return [];
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sources: {
      type: Array,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    checkEmail(email) {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `check-customer`,
          data: {
            email,
            id: this.reservation.id,
          },
        })
        .then((data) => {
          _this.customer_email = data;

          console.log(_this.customer_email, "_this.customer_email");

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
       console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    checkPhone(mobile) {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `check-mobile-customer`,
          data: {
            mobile,
            id: this.reservation.id,
          },
        })
        .then((data) => {
          _this.customer_mobile = data;

          console.log(_this.customer_mobile, "_this.customer_email");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    filterTuesdays(date) {
      // Disable all Tuesdays
      return date.getDay() !== 2; // 0: Sunday, 1: Monday, ..., 6: Saturday
    },
    disableTuesdaySelection(selectedDate) {
      const date = new Date(selectedDate);
      if (date.getDay() === 2) {
        // If it's Tuesday, prevent the selection
        return false;
      }
      return true;
    },
    searchingIteams() {
      let type = "";
      const regex = /[a-zA-Z]/;

      // Checking if search contains alphabets
      if (regex.test(this.search)) {
        type = "string";
      } else {
        type = "integer";
      }

      // Handling different cases based on type
      if (this.search && type === "integer") {
        // Clearing customer details if the search is numeric
        this.reservation.first_name = null;
        this.search = null;
      } else if (!this.search) {
        // Clearing customer details if search is empty
        this.reservation.first_name = null;
      } else {
        // Assuming this.search is a string (contains alphabets)
        // Updating customer details based on the search
        this.reservation.customer_name = this.reservation?.first_name?.first_name;
        this.reservation.mobile = this.reservation?.first_name?.phone_number;
        this.reservation.email = this.reservation?.first_name?.email;
        this.reservation.id = this.reservation?.first_name?.id;
      }
    },
    searchingIteam() {
      this.reservation.customer_name = this.reservation?.first_name?.first_name;
      this.reservation.mobile = this.reservation?.first_name?.phone_number;
      this.reservation.email = this.reservation?.first_name?.email;
      this.reservation.id = this.reservation?.first_name?.id;
    },
    getcustomers() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `customer`,
        })
        .then((data) => {
          _this.customersss = data.rows;
          const customerssss = _this.customersss.map((item) => {
            return {
              text: item.first_name + "(" + item.phone_number + ")",
              first_name: item.first_name,
              phone_number: item.phone_number,
              email: item.email,

              customers_id: item.id,
            };
          });
          _this.customerss = customerssss;
          console.log(customerssss, "customerssss");

          console.log(_this.customerss, "_this.customerss");
          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
       console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    generateTimeSlots(date) {
      // Assuming we start from 9:00 AM and end at 6:00 PM
      const startTime = 12 * 60; // 9:00 AM in minutes
      const endTime = 15 * 60; // 6:00 PM in minutes
      const interval = 30; // Time slot interval in minutes
      // const  date_bill=MomentJS().format("YYYY-MM-DD");
      const now = new Date();
      const currentDate = new Date(date);

      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentTime = currentHour * 60 + currentMinute;
      //console.log(currentTime,'currentTime')
      const selectedDate = currentDate.setHours(0, 0, 0, 0);
      const today = now.setHours(0, 0, 0, 0);
      let timeData = [];
      //console.log(startTime, endTime);

      for (let i = startTime; i < endTime; i += interval) {
        console.log(i);
        const hours24 = Math.floor(i / 60);
        const minutes = i % 60;

        // Convert to 12-hour format
        let hours12 = hours24 % 12;
        hours12 = hours12 === 0 ? 12 : hours12; // Convert 0 to 12

        // Determine AM/PM
        const ampm = hours24 < 12 ? "AM" : "PM";

        const timeString = `${hours12.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")} ${ampm}`;
        let disabled = false;
        if (selectedDate === today) {
          disabled = i < currentTime; // Disable time slots in the past for today's date
          //console.log(i, disabled, 'disabled')
        }

        timeData.push({ disabled, time: timeString });
      }
      this.timeSlots = timeData;
      this.generatelunchTimeSlots(date);
      this.generatedinnerTimeSlots(date);
    },
    generatelunchTimeSlots(date) {
      // Assuming we start from 9:00 AM and end at 6:00 PM
      const startTime = 15 * 60; // 9:00 AM in minutes
      const endTime = 19 * 60; // 6:00 PM in minutes
      const interval = 30; // Time slot interval in minutes
      // const  date_bill=MomentJS().format("YYYY-MM-DD");
      const now = new Date();
      const currentDate = new Date(date);

      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentTime = currentHour * 60 + currentMinute;
      //console.log(currentTime,'currentTime')
      const selectedDate = currentDate.setHours(0, 0, 0, 0);
      const today = now.setHours(0, 0, 0, 0);
      let timeData = [];
      //console.log(startTime, endTime);

      for (let i = startTime; i < endTime; i += interval) {
        console.log(i);
        const hours24 = Math.floor(i / 60);
        const minutes = i % 60;

        // Convert to 12-hour format
        let hours12 = hours24 % 12;
        hours12 = hours12 === 0 ? 12 : hours12; // Convert 0 to 12

        // Determine AM/PM
        const ampm = hours24 < 12 ? "AM" : "PM";

        const timeString = `${hours12.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")} ${ampm}`;
        let disabled = false;
        if (selectedDate === today) {
          disabled = i < currentTime; // Disable time slots in the past for today's date
          //console.log(i, disabled, 'disabled')
        }

        timeData.push({ disabled, time: timeString });
      }
      this.timeLunchSlots = timeData;
      console.log(this.timeLunchSlots, "this.timeLunchSlots");
    },
    generatedinnerTimeSlots(date) {
      // Assuming we start from 9:00 AM and end at 6:00 PM
      const startTime = 19 * 60; // 9:00 AM in minutes
      const endTime = 24 * 60; // 6:00 PM in minutes
      const interval = 30; // Time slot interval in minutes
      // const  date_bill=MomentJS().format("YYYY-MM-DD");
      const now = new Date();
      const currentDate = new Date(date);

      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentTime = currentHour * 60 + currentMinute;
      //console.log(currentTime,'currentTime')
      const selectedDate = currentDate.setHours(0, 0, 0, 0);
      const today = now.setHours(0, 0, 0, 0);
      let timeData = [];
      //console.log(startTime, endTime);

      for (let i = startTime; i < endTime; i += interval) {
        console.log(i);
        const hours24 = Math.floor(i / 60);
        const minutes = i % 60;

        // Convert to 12-hour format
        let hours12 = hours24 % 12;
        hours12 = hours12 === 0 ? 12 : hours12; // Convert 0 to 12

        // Determine AM/PM
        const ampm = hours24 < 12 ? "AM" : "PM";

        const timeString = `${hours12.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")} ${ampm}`;
        let disabled = false;
        if (selectedDate === today) {
          disabled = i < currentTime; // Disable time slots in the past for today's date
          //console.log(i, disabled, 'disabled')
        }

        timeData.push({ disabled, time: timeString });
      }
      this.timedinnerSlots = timeData;
      console.log(this.timedinnerSlots, "this.timeLunchSlots");
    },
    async saveRecord() {
      const _this = this;
      if (!_this.$refs.customerForm.validate()) {
        return false;
      }

      if (!this.selectSlot) {
        this.$store.commit(SET_ERROR, [
          { model: true, message: "The booking time field is required" },
        ]);
        return false;
      }

      this.reservation.booking_time = this.selectSlot;
      console.log(this.total, "sff");

      try {
        _this.pageLoading = true;
        if (_this.id) {
          const customer = await UPDATE_RESERVATION(_this.id, _this.reservation);
          console.log(customer);
          this.recordPaymentDialog = false;
          _this.$router.replace({
            name: "reservation",

            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Reservation Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_RESERVATION(_this.reservation);
          console.log(customer);
          this.recordPaymentDialog = false;

          _this.$router.replace({
            name: "reservation",
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Reservation Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
        _this.reservation = {};
        this.$emit("success", "true");
        this.$emit("close", "false");
      }
    },

    getTotal() {
      this.product = this.total;

      let sum = 0;

      this.total?.forEach((element) => {
        sum = sum + Number(element.total);
      });
      this.amount = Number(sum);
      this.paymentRecordData.payment_made = this.amount;
      this.paymentRecordData.payment_number = this.product.length;

      return this.amount;
    },
  },
  // methods:{
  //   getTotal(){
  //          this.product = this.total

  // 			let sum = 0;

  // 			this.product?.forEach((element) => {

  // 				sum = sum + Number(element.total);
  // 			});
  //       console.log(sum)
  // 			this.total = Number(sum);
  // 			//  this.getDiscount();
  //       console.log(this.total_discount)
  //       this.$emit("input", this.total);
  // 			return this.total;

  //       },

  // },
  mounted() {
    this.generateTimeSlots();
    this.generatelunchTimeSlots();
    this.generatedinnerTimeSlots();
    this.currentDate = new Date().toLocaleDateString();

    // Calculate date 15 days ago
    const currentDate = moment();

    // Subtract 15 days from the current date
    this.dateFifteenDaysAgo = currentDate.subtract(15, "days").format("YYYY-MM-DD");

    console.log(this.dateFifteenDaysAgo);

    this.getcustomers();
  },

  components: {
    Dialog,
    //FileUpload,
    // PurchaseDocument,
    AutoCompleteInput,
    BookingDate,
    TextAreaInput,
    PhoneTextField,
    EmailInput,
    ReservationPerson,
    //SelectInput,
    //QuantityInput,
  },
};
</script>
<style scoped>
.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
.onhover:hover {
  color: #6775e0 !important;
}
</style>

<template>
  <Dialog :dialog.sync="documentDialog" @close="$emit('close')">
    <template v-slot:title>Upload Document</template>
    <template v-slot:body>
      <v-form
        ref="documentForm"
        v-model.trim="export_valid"
        lazy-validation
        v-on:submit.stop.prevent="submitDocument()"
      >
        <p class="btx-small-p ml-2 nmb-2">
          Maximum File Size: 5 MB | File Format: DOC DOCX XLS XLSX JPG JPEG PNG JFIF PDF
        </p>
        <table style="table-layout: fixed" class="table-document" width="100%">
          <template v-for="(file, index) in files">
            <tr :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
              <td width="45%" class="px-2">
                <v-file-input
                  :id="`document-file-${index}`"
                  placeholder="Select File"
                  outlined
                  class="mt-3"
                  prepend-icon=""
                  prepend-inner-icon="mdi-attachment"
                  hide-details
                  v-model="file.file"
                  v-on:change="updateFile(index, $event)"
                  v-on:click:clear="updateFile(index, $event)"
                ></v-file-input>
              </td>
              <td width="45%" class="px-2">
                <TextInput
                  :id="`document-name-${index}`"
                  v-model="file.name"
                  hide-details
                  placeholder="File Name"
                  :suffix="file.suffix"
                ></TextInput>
              </td>
              <td width="10%" align="center" valign="middle" class="px-2">
                <v-btn
                  :disabled="files.length < 2"
                  v-on:click="removeFile(index)"
                  color="red lighten-1 white--text"
                  class="mt-3"
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <!-- <tr :key="`file-remark-${index}`" :class="{ 'table-alternate-row': index % 2 }">
							<td colspan="2" width="90%" class="px-2 py-3">
								<TextAreaInput
									:rows="2"
									dense
									:id="`document-remark-${index}`"
									hide-details
									v-model="file.remark"
									:word-limit="200"
									placeholder="Enter remark..."
									:disabled="attachLoading"
									:loading="attachLoading"
								></TextAreaInput>
							</td>
						</tr> -->
          </template>
        </table>
        <v-btn
          color="blue darken-4 white--text"
          class="my-2 ml-2"
          tile
          :disabled="files.length > 4"
          depressed
          v-on:click="addMore()"
        >
          <v-icon>mdi-plus</v-icon> Add More
        </v-btn>
        <!-- <p class="ml-2 red--text" v-if="files.length > 4">Can't add more than 5 files.</p> -->
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!export_valid || attachLoading"
        :loading="attachLoading"
        class="white--text"
        depressed
        color="blue darken-4"
        tile
        v-on:click="submitDocument()"
      >
        Submit
      </v-btn>
      <v-btn
        depressed
        tile
        :disabled="attachLoading"
        :loading="attachLoading"
        v-on:click="$emit('close', true)"
      >
        Closes
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
//import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import Dialog from "@/view/components/Dialog.vue";
import TextInput from "@/view/components/TextInput";

import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
  name: "export-dialog",
  data() {
    return {
      export_valid: true,
      attachLoading: false,
      files: [
        {
          file: null,
          name: null,
          suffix: null,
        },
      ],
    };
  },
  props: {
    documentDialog: {
      type: Boolean,
      default: false,
    },
    chat: {
      type: Number,
      default: 1,
    },
    relatedId: {
      type: Number,
      default: 0,
    },
    parentId: {
      type: Number,
      default: 0,
    },
    relatedType: {
      type: String,
      default: null,
    },
  },
  watch: {
    documentDialog(param) {
      if (param) {
        this.files = [
          {
            file: null,
            name: null,
            suffix: null,
          },
        ];
      }
    },
  },
  methods: {
    addMore() {
      this.files.push({
        file: null,
        name: null,
        suffix: null,
      });
    },
    submitDocument() {
      const _this = this;

      const formErrors = _this.validateForm(_this.$refs.documentForm);

      if (formErrors.length) {
        _this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
        return false;
      }

      if (!_this.$refs.documentForm.validate()) {
        return false;
      }

      let errArr = [];

      let cloneFiles = cloneDeep(this.files);

      let nullFiles = cloneFiles.filter((ff) => {
        if (ff.file == null || !ff.file) {
          let _obj;
          if (ff.name) {
            _obj = { model: true, message: `File is missing for : ${ff.name}` };
            errArr.push(_obj);
          }
          return ff;
        }
      });

      let nullNames = cloneFiles.filter((ff) => {
        if (ff.name == null || !ff.name) {
          let _obj;
          if (ff.file) {
            _obj = { model: true, message: `File name is missing for : ${ff.file.name}` };
            errArr.push(_obj);
          }
          return ff;
        }
      });

      if (cloneFiles.length == 1 && nullFiles.length == 1 && nullNames.length == 1) {
        if (nullFiles.length && nullNames.length) {
          this.$store.commit(SET_ERROR, [{ model: true, message: "Select atleast one file." }]);
          return false;
        }
      } else {
        if (errArr.length) {
          this.$store.commit(SET_ERROR, [...errArr]);
          return false;
        }
      }

      const params = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        if (this.files && this.files[i] && this.files[i].file) {
          params.append(`file[${i}][file]`, this.files[i].file);
        }
        if (this.files && this.files[i] && this.files[i].name) {
          params.append(`file[${i}][name]`, this.files[i].name);
        }
      }
      params.append(`chat`, this.chat);
      params.append("related_id", this.relatedId);
      params.append("type", this.relatedType);
      if (this.parentId) {
        params.append("parent", this.parentId);
      }
      this.attachLoading = true;

      ApiService.setHeader();
      ApiService.upload("all-documents", params)
        .then(() => {
          this.$emit("success", true);
          this.$emit("close", true);
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
        });
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.files[index].name = null;
        this.files[index].suffix = null;
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },
  },
  components: {
    Dialog,
    TextInput,
    //	AutoCompleteInput,
    //TextAreaInput,
  },
  mounted() {
    //	this.customerList = this.localDB("customers", []);
  },
  computed: {
    //...mapGetters(["errors", "localDB"]),
  },
};
</script>

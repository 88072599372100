<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="8" class="my-auto">
            <v-layout>
              <v-flex md4 class="my-auto">
                <h1 class="custom-header-blue-text m-0 text-truncate">{{ pageTitle() }}</h1></v-flex
              >
              <v-flex md8>
                <v-btn
                  :disabled="pageLoading"
                  class="mr-2 white--text"
                  depressed
                  v-on:click="orderDialog = true"
                  color="blue-grey lighten-1"
                  tile
                >
                  New Order
                </v-btn>
                <v-btn
                  :disabled="pageLoading || rLoading"
                  class="ml-2 white--text"
                  depressed
                  v-on:click="getCollectedVouchers()"
                  color="deep-orange darken-4"
                  tile
                >
                  Redeem Voucher
                </v-btn>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="4" class="text-right">
            <v-btn
              :disabled="pageLoading"
              depressed
              tile
              class="mx-2 white--text"
              color="blue darken-4"
              :to="{
                name: 'customer-update',
                params: { id: customerId },
                query: { t: new Date().getTime() },
              }"
            >
              Edit
            </v-btn>
            <v-btn
              :disabled="pageLoading"
              depressed
              tile
              class="mx-2 white--text"
              color="red lighten-1"
              v-on:click="deleteCustomerConfirm()"
            >
              Delete
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Back
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          v-if="pageLoading"
          class="position-absolute"
          color="blue darken-4"
        ></v-progress-linear>
        <v-container fluid>
          <table width="100%" class="detail-table mb-10">
            <tr>
              <th width="200">Name</th>
              <td><ValueTemplate v-model="customer.full_name" title="Name"></ValueTemplate></td>
              <th width="200">Unit #</th>
              <td><ValueTemplate v-model="customer.unit_number" title="Unit #"></ValueTemplate></td>
                    <!-- <th width="200">Address Line 2</th>

              <td>
                <ValueTemplate
                  v-model="customer.address_line_2"
                  title="Address Line 2"
                ></ValueTemplate>
              </td> -->
            </tr>
            <tr>
              <th width="200">Date of Birth</th>
              <td>
                <ValueTemplate
                  date
                  v-model="customer.date_of_birth"
                  title="Date of Birth"
                ></ValueTemplate>
              </td>
              <th width="200">Address Line 1</th>
              <td>
                <ValueTemplate
                  v-model="customer.address_line_1"
                  title="Address Line 1"
                ></ValueTemplate>
              </td>
              <!-- <th width="200">Gender</th>
              <td>
                <ValueTemplate v-model="customer.gender" title="gender"></ValueTemplate>
              </td> -->
            </tr>
            <tr>
              <th width="200">Phone Number</th>
              <td>
                <ValueTemplate v-model="customer.phone_number" title="Phone Number"></ValueTemplate>
              </td>
              <th width="200">Address Line 2</th>

<td>
  <ValueTemplate
    v-model="customer.address_line_2"
    title="Address Line 2"
  ></ValueTemplate>
</td>
              <!-- <th width="200">Address Line 1</th>
              <td>
                <ValueTemplate
                  v-model="customer.address_line_1"
                  title="Address Line 1"
                ></ValueTemplate>
              </td> -->
            </tr>
            <tr>
              <th width="200">Email</th>
              <td >
                <ValueTemplate v-model="customer.email" title="Email"></ValueTemplate>
              </td>
              
           
              <!-- <th width="200">Address Line 2</th>
              <td>
                <ValueTemplate
                  v-model="customer.address_line_2"
                  title="Address Line 2"
                ></ValueTemplate>
              </td> -->
              <th width="200">Postal Code</th>
              <td>
                <ValueTemplate v-model="customer.postal_code" title="Postal Code"></ValueTemplate>
              </td>
            </tr>
            <tr>
              <!-- <th width="200">Postal Code</th>
              <td>
                <ValueTemplate v-model="customer.postal_code" title="Postal Code"></ValueTemplate>
              </td> -->
              <th width="200">Gender</th>
              <td>
                <ValueTemplate v-model="customer.gender" title="gender"></ValueTemplate>
              </td>

              <th width="200">Country</th>
              <td><ValueTemplate v-model="customer.country" title="Country"></ValueTemplate></td>
            </tr>
            <tr>
              <!-- <th width="200">Type</th>
              <td>
                <v-chip small outlined label class="text-capitalize" color="blue"
                  ><span style="text-transform: uppercase">{{ customer.type }}</span>
                </v-chip>
              </td> -->
              <th width="200">Date of Joining</th>
              <td>
                <ValueTemplate
                  date
                  v-model="customer.joining_date"
                  title="Date of Joining"
                ></ValueTemplate>
              </td>

              <th width="200">Type</th>
              <td>
                <v-chip small outlined label class="text-capitalize" color="blue"
                  ><span style="text-transform: uppercase">{{ customer.type }}</span>
                </v-chip>
              </td>
            </tr>
            <tr v-if="customer.type != 'gold'">
              <th width="200">Expiry</th>
              <td>
                <v-chip small outlined label class="text-capitalize" color="red">
                  {{ days }} DAYS
                </v-chip>
              </td>
              <th width="200">Expiry Date</th>
              <td>
                <ValueTemplate
                  date
                  v-model="customer.expiry_date"
                  title="Expiry Date"
                ></ValueTemplate>
              </td>
            </tr>
          </table>

          <v-tabs
            v-model="customerTab"
            background-color="transparent"
            color="blue"
            class="custom-tab-transparent"
            active-class="blue darken-4 text-white"
            hide-slider
          >
            <v-tab href="#orders"> Orders ({{ customer.total_orders }}) </v-tab>
            <v-tab href="#reservation"> Reservations ({{ customer?.booking?.length }}) </v-tab>
            <v-tab href="#redeemed"> Reedemed Vouchers ({{ customer.total_vredeemed }}) </v-tab>
            <v-tab href="#expired"> Expired Vouchers ({{ customer.total_vexpired }}) </v-tab>
            <!-- <v-tab href="#claims">
              Redeem
            </v-tab>
            <v-tab href="#reward-claims">
              Point Redeem
            </v-tab> -->
            <v-tabs-items v-model="customerTab">
              <v-tab-item value="orders">
                <v-row>
                  <v-col md="6" class="py-0">
                    <TextInput
                      clearable
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="pos-bill-search"
                      placeholder="Search POS Bill #"
                      v-model="search"
                      v-on:click:clear="getOrders()"
                      v-on:keydown:esc="getOrders()"
                      v-on:keydown:enter="getOrders()"
                      v-on:keydown:tab="getOrders()"
                    ></TextInput>
                  </v-col>
                </v-row>
                <v-simple-table class="bt-table mt-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="100">Actions</th>
                        <th width="50">Order #</th>
                        <th width="100">POS Bill #</th>
                        <th width="200">Date Time</th>
                        <th width="200">Amount</th>
                        <th width="200">Point Earned</th>
                        <th width="200">Point Reedemed</th>
                      </tr>
                    </thead>
                    <tbody v-if="!pageLoading">
                      <template v-if="orders.length">
                        <tr v-for="(row, bkey) in orders" :key="bkey">
                          <td width="100">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  fab
                                  dark
                                  x-small
                                  color="green"
                                  v-bind="attrs"
                                  class="mr-1"
                                  v-on="on"
                                  v-on:click.stop.prevent="routeToUpdate(row)"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  fab
                                  dark
                                  x-small
                                  color="red"
                                  class="mx-1"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-on:click.stop.prevent="deleteConfirm(row)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                            <template v-if="!row.vcollected">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    depressed
                                    fab
                                    dark
                                    x-small
                                    class="mx-1"
                                    color="cyan darken-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:click.stop.prevent="
                                      getAvailableVouchers(row.id, row.barcode)
                                    "
                                  >
                                    <v-icon>mdi-ticket-percent-outline mdi-rotate-135</v-icon>
                                  </v-btn>
                                </template>
                                <span>Collect Voucher</span>
                              </v-tooltip>
                            </template>
                            <template v-if="!row.vredeemed">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    depressed
                                    fab
                                    dark
                                    :disabled="pageLoading || rLoading"
                                    x-small
                                    class="ml-1"
                                    color="deep-orange darken-4"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:click.stop.prevent="
                                      getCollectedVouchers(row.id, row.barcode)
                                    "
                                  >
                                    <v-icon>mdi-ticket-percent-outline mdi-rotate-45</v-icon>
                                  </v-btn>
                                </template>
                                <span>Redeem Voucher</span>
                              </v-tooltip>
                            </template>
                          </td>
                          <td width="50"><Chip :text="row.barcode"></Chip></td>
                          <td width="100"><Chip :text="row.bill_no"></Chip></td>
                          <td width="200">{{ formatDateTime(row.datetime) }}</td>
                          <td width="200">{{ formatCurrency(row.amount) }}</td>
                          <td width="200">{{ row.point_earned }}</td>
                          <td width="200">{{ row.point_redeemed }}</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="7">
                            <p class="m-0 text-center">
                              <img
                                width="30"
                                :src="$assetURL('media/error/empty.png')"
                                class="row-not-found-image mr-4"
                              />
                              Uhh... There are no order at the moment.
                            </p>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                    <tfoot v-else>
                      <tr v-for="idr in 7" :key="idr">
                        <td v-for="idk in 7" :key="idk">
                          <v-skeleton-loader type="text"></v-skeleton-loader>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
                <v-layout v-if="orders.length" class="light-border-top mt-4">
                  <v-flex md6>
                    <label class="btx-label p-4"
                      >Showing {{ showingFrom }} to {{ showingTo }} of
                      {{ totalRows }} entries</label
                    >
                  </v-flex>
                  <v-flex md6>
                    <v-pagination
                      color="blue darken-4"
                      v-model="currentPage"
                      :length="pageLimit"
                      :total-visible="7"
                    ></v-pagination>
                  </v-flex>
                </v-layout>
              </v-tab-item>

              <v-tab-item value="reservation">
                <Reservation
                  v-if="customerId"
                  reload="reload"
                  :customer-id="customerId"
                ></Reservation>
              </v-tab-item>
              <v-tab-item value="redeemed">
                <RedeemedTemplate
                  v-if="customerId"
                  reload="reload"
                  :customer-id="customerId"
                ></RedeemedTemplate>
              </v-tab-item>
              <v-tab-item value="expired">
                <ExpiredTemplate
                  v-if="customerId"
                  reload="reload"
                  :customer-id="customerId"
                ></ExpiredTemplate>
              </v-tab-item>
              <!-- <v-tab-item value="claims"> </v-tab-item>
              <v-tab-item value="reward-claims"> </v-tab-item> -->
            </v-tabs-items>
          </v-tabs>
        </v-container>
      </v-col>
    </v-row>

    <OrderTemplate
      v-model="order"
      :customer-id="customerId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="getOrders()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    ></OrderTemplate>
    <DeleteTemplate
      type="Order"
      :delete-text="deleteText"
      delete-note="All transactions of this order will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getOrders()"
    ></DeleteTemplate>
    <DeleteTemplate
      type="Customer"
      :delete-text="cDeleteText"
      delete-note="All transactions of this customer will also be deleted."
      :delete-dialog="cDeleteDialog"
      :delete-endpoint="cDeleteEndpoint"
      v-on:close="cDeleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    
    <!-- <RedeemTemplate
      :dialog="redeemDialog"
      :order-id="rOrderId"
      :order-code="rOrderCode"
      :vouchers="rVouchers"
      v-on:close="vRedeemed()"
      v-on:redeemed="vRedeemed()"
    ></RedeemTemplate> -->
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import Chip from "@/view/components/Chip";
import ValueTemplate from "@/view/components/ValueTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
// import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import Reservation from "./Reservation";
import RedeemedTemplate from "@/view/components/RedeemedVoucherTemplate";
import ExpiredTemplate from "@/view/components/ExpiredVoucherTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CUSTOMER } from "@/core/lib/customer.lib";
import { GET_ORDERS } from "@/core/lib/order.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import {
  COMMON_EVENT_BUS,
  GET_AVAILABLE_VOUCHERS,
  GET_COLLECTED_VOUCHERS,
} from "@/core/lib/common.lib";
import { format } from "date-fns";

export default {
  name: "customer-detail",
  title: "Detail Customer",
  data() {
    return {
      speedDial: [],
      days: 0,
      cLoading: false,
      rLoading: false,
      cDeleteText: null,
      cDeleteDialog: false,
      cDeleteEndpoint: null,
      deleteDialog: false,
      deleteEndpoint: null,
      deleteText: null,
      pageLoading: true,
      orderDialog: false,
      collectDialog: false,
      redeemDialog: false,
      customerId: null,
      customerTab: "orders",
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      search: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
      customer: {
        barcode: null,
        title: null,
        first_name: null,
        last_name: null,
        full_name: null,
        email: null,
        date_of_birth: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        unit_number: null,
        postal_code: null,
        country: null,
        total_orders: null,
        total_vcollected: null,
        total_vredeemed: null,
        total_vexpired: null,
        added_by: null,
        added_at: null,
        updated_by: null,
        updated_at: null,
      },
      orders: [],
      cVouchers: [],
      cOrderId: 0,
      cOrderCode: null,
      rOrderId: 0,
      rVouchers: [],
      rOrderCode: null,
    };
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },
  methods: {
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.getOrders();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.getOrders();
    },
    deleteCustomerConfirm() {
      this.cDeleteText = this.customer.barcode;
      this.cDeleteDialog = true;
      this.cDeleteEndpoint = `customer/${this.customerId}`;
    },
    deleteConfirm(row) {
      this.deleteDialog = true;
      this.deleteText = row.barcode;
      this.deleteEndpoint = `customer/${this.customerId}/order/${row.id}`;
    },
    clearVars() {
      this.orderDialog = false;
      this.deleteDialog = false;
      this.deleteText = null;
      this.deleteEndpoint = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    pageTitle() {
      if (this.customer.full_name) {
        return this.customer.full_name;
      }
      return "Detail Customer";
    },
    routeToUpdate(row) {
      this.order = {
        id: row.id,
        date: row.date,
        time: row.time,
        bill_no: row.bill_no,
        amount: row.amount,
        voucher: row.voucher,
      };
      this.orderDialog = true;
    },
    async getCustomer() {
      const _this = this;
      try {
        const customer = await GET_CUSTOMER(_this.customerId);
        _this.getOrders();
        _this.customer = customer.customer;
        _this.days = customer.days;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Customer", disabled: true },
          { text: "Detail", disabled: true },
          { text: customer.barcode, disabled: true },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getOrders() {
      const _this = this;
      const form = {
        current_page: _this.currentPage,
        search: _this.search,
      };
      try {
        _this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_ORDERS(
          _this.customerId,
          form
        );
        _this.clearVars();
        _this.orders = rows;
        _this.pageLimit = totalPages;
        _this.showingFrom = showingFrom;
        _this.showingTo = showingTo;
        _this.totalRows = totalRows;
        COMMON_EVENT_BUS.$emit("orders:loaded", true);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    const { id } = this.$route.params;
    if (id) {
      this.customerId = toSafeInteger(id);
      this.getCustomer();
    } else {
      this.goBack();
    }
  },
  components: {
    Chip,
    TextInput,
    ValueTemplate,
    Reservation,
    RedeemedTemplate,
    ExpiredTemplate,
    CollectTemplate,
    // RedeemTemplate,
    DeleteTemplate,
    OrderTemplate,
  },
};
</script>
